import styled from "styled-components"

export const LogoWrapper = styled.div`
  display: flex;
  max-width: 240px;
`

export const LogoStyled = styled.img`
  margin: 0;
  padding: 0 30px;
  cursor: pointer;
  height: auto;
  width: auto;
  max-height: 50px;
  object-fit: contain;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    &.full {
      margin: 0 auto !important;
    }
  }
`
export const LogoSmallStyled = styled.img`
  max-height: 25px;
  margin: 0;
  padding: 0 20px;
  cursor: pointer;

  &.full {
    margin: 0 auto !important;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`
