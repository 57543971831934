import "react-datepicker/dist/react-datepicker.min.css"

import React, {
  useEffect,
  useState
} from "react"
import DatePicker, { registerLocale } from "react-datepicker"
import { useTranslation } from "react-i18next"
import { components as ReactSelectComponents } from "react-select"

import classnames from "classnames"
import PropTypes from "prop-types"

import Checkbox from "@atoms/checkbox"
import ErrorMsg from "@atoms/error"
import Icon from "@atoms/icon"
import MarkdownText from "@atoms/markdowntext"

import { dayjsLocale } from "@utils/dayjsLocale"
import { getMidnight } from "@utils/functions"

import { InputStyled, SelectStyled } from "./input.styled"

const midnight = getMidnight(new Date())

const propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  patter: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
}

const defaultProps = {
  /** Default Props go here */
  onChangeInput: () => {},
  onKeyDownInput: () => {},
  onKeyUpInput: () => {},
  type: "text",
}

/**
 * Input
 *
 * @param {string}    type              Type of input element (default is text)
 * @param {string}    name              Name
 * @param {string}    pattern           Pattern
 * @param {boolean}   required          Required
 * @param {string}    placeholder       Placeholder
 * @param {component} prefixComponent   Prefix Component
 * @param {string}    errorMessage      Errore message
 *
 */

const Tag = ({ toggle, item, selected }) => {
  const { label, locked } = item
  const [error, setError] = useState(null)
  const { t } = useTranslation()

  const toggler = () => {
    if (locked && selected) {
      setError(null)
      setTimeout(() => {
        setError(
          t("errors.lockedTeams", {
            team: label,
          })
        )
      }, 100)
    } else toggle && toggle()
  }

  return (
    <>
      <div
        className={classnames("tag", {
          selected: selected,
          error: error,
        })}
      >
        {label}
        <div
          className={classnames("sign", { selected: selected })}
          onClick={toggler}
        >
          <div className={classnames("signText")}>{selected ? "×" : "+"}</div>
        </div>
      </div>
      <ErrorMsg msg={error} toast />
    </>
  )
}

const now = new Date()

const InputSwitcher = props => {
  const {
    type,
    computedType,
    name,
    options,
    disabled,
    placeholder,
    dateFormat,
    timeFormat,
    selectStyles,
    autocomplete,
    onRender,
    onChangeInput,
    onKeyDownInput,
    onKeyUpInput,
    otherProps,
  } = props

  useEffect(() => {
    onRender && onRender()
  }, [])

  const {
    value,
    defaultValue,
    isSearchable,
    minTime = midnight,
    timeIntervals = 10,
    onChange,
  } = otherProps
  const { t } = useTranslation()

  const targetedOnChange = value => {
    let parsedValue = value
    if (type === "date") {
      parsedValue = new Date(value)
    }
    onChange && onChange({ target: { value: parsedValue, name } })
  }

  const tagExists = option => value.some(s => s.value === option.value)

  const tagToggle = option => {
    const exists = tagExists(option)

    const addTag = () => [].concat(value, option)
    const removeTag = () => value.filter(t => t.value !== option.value)

    const newTags = exists ? removeTag() : addTag()
    targetedOnChange(newTags)
  }

  useEffect(() => {
    if (type === "select" && value && options) {
      const [foundOption = {}] = options.filter(o => o.value === value.value)
      targetedOnChange(foundOption)
    }
  }, [])

  switch (type) {
    case "select":
      const defaultOption = defaultValue
        ? options.filter(o => o.value === defaultValue)[0]
        : null

      useEffect(() => {
        if (!defaultOption) return
        targetedOnChange(defaultOption)
      }, [defaultOption])

      const optionsWithPlaceholder = options
        ? placeholder
          ? [{ label: placeholder, value: "", disabled: true }, ...options]
          : options
        : []

      const { Option } = ReactSelectComponents

      const CustomSelectOption = props => {
        const { data } = props
        const { label, icon, iconStyle = {}, iconPosition = "right" } = data
        return (
          <Option {...props}>
            {iconPosition === "left" && icon && (
              <Icon name={icon} style={iconStyle} />
            )}
            {label}
            {iconPosition === "right" && icon && (
              <Icon name={icon} style={iconStyle} />
            )}
          </Option>
        )
      }

      const CustomSelectValue = props => {
        const { data } = props
        const { label, icon, iconStyle = {}, iconPosition = "right" } = data
        return (
          <div>
            {iconPosition === "left" && icon && (
              <Icon name={icon} style={iconStyle} />
            )}
            {label}
            {iconPosition === "right" && icon && (
              <Icon name={icon} style={iconStyle} />
            )}
          </div>
        )
      }

      return (
        <SelectStyled
          name={name}
          options={optionsWithPlaceholder}
          disabled={disabled}
          placeholder={placeholder}
          classNamePrefix={"Select"}
          styles={selectStyles}
          onFocus={e => {
            if (!isSearchable && !e.target.readonly && !autocomplete) {
              e.target.setAttribute("readonly", true)
            }
          }}
          {...otherProps}
          isSearchable={isSearchable}
          onChange={targetedOnChange}
          components={{
            Option: CustomSelectOption,
            SingleValue: CustomSelectValue,
          }}
        />
      )
    case "tags":
      return (options || []).map((item, i) => (
        <Tag
          key={i}
          toggle={() => tagToggle(item)}
          tagExists
          selected={tagExists(item)}
          item={item}
        />
      ))
    case "date":
      const months = dayjsLocale().months(true)
      const days = dayjsLocale().weekdaysShort()

      registerLocale("customLocale", {
        localize: {
          month: n => months[n],
          day: n => days[n],
        },
        formatLong: {},
      })

      useEffect(() => {
        if (!defaultValue) return
        targetedOnChange(defaultValue) // avoid getting it as UTC, always manage the date in current Timezone
      }, [defaultValue])

      return (
        <DatePicker
          {...otherProps}
          onChange={e => {
            targetedOnChange(e)
          }}
          selected={value && value.getDate ? value : null}
          popperClassName={"datePopper"}
          dayClassName={() => "day"}
          locale={"customLocale"}
          placeholderText={placeholder}
          dateFormat={dateFormat || t("formats.datepicker")}
        />
      )
    case "time":
      const maxTime = getMidnight(new Date(), false, "end")
      return (
        <DatePicker
          {...otherProps}
          onChange={e => {
            targetedOnChange(e)
          }}
          selected={value && value.getDate ? value : null}
          popperClassName={"timePopper"}
          className={"timePicker"}
          dayClassName={() => "day"}
          placeholderText={placeholder}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={timeIntervals}
          minTime={minTime}
          maxTime={maxTime}
          dateFormat={timeFormat || t("formats.time")}
          timeFormat={timeFormat || t("formats.time")}
        />
      )
    case "checkbox":
      return (
        <Checkbox
          {...otherProps}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          checked={value === true}
          value={!!value ? value : false}
          onChange={e => {
            onChange && onChange(e)
            onChangeInput && onChangeInput(e.target.value)
          }}
        />
      )
    default:
      return (
        <input
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          onKeyDown={event => onKeyDownInput(event)}
          onKeyUp={event => onKeyUpInput(event)}
          type={computedType}
          value={value}
          onChange={e => {
            onChange && onChange(e)
            onChangeInput && onChangeInput(e.target.value)
          }}
        />
      )
  }
}

const Input = ({
  intl,
  onChangeInput,
  onKeyDownInput,
  onKeyUpInput,
  disabled,
  name,
  iconName,
  results,
  prefixComponent,
  error,
  hasBorder,
  className,
  autocomplete,
  type,
  placeholder,
  errorStyle,
  labelStyle,
  labelClassName,
  labelRightClassName,
  labelRightStyle,
  maxHeight = 200,
  options,
  onClickLink,
  placeholderProps = { disabled: true },
  ...otherProps
}) => {
  const [maskType, setMaskType] = useState(null)
  const computedType = maskType || type
  const isMaskText = maskType === "text"

  const togglePwd = () => {
    const newType = isMaskText ? "password" : "text"
    setMaskType(newType)
  }

  const iconPwd = isMaskText ? "eye-hide" : "eye"

  const selectStyles = {
    container: base => ({
      ...base,
      height: "100%",
      fontSize: 14,
      display: "flex",
      alignItems: "center",
      width: "100%",
    }),
    control: base => ({
      ...base,
      cursor: "pointer",
      height: "100%",
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "none",
      width: "100%",
      "&:hover": {
        border: "none !important",
        boxShadow: "none !important",
      },
    }),
    menu: base => ({
      ...base,
      boxShadow: "0 10px 10px 0 rgba(14, 37, 93, 0.08)",
      marginTop: 14,
      borderRadius: 4,
      marginLeft: -14,
      width: "calc(100% + 28px)",
    }),
    menuList: base => ({ ...base, padding: 0, borderRadius: 4, maxHeight }),
    valueContainer: base => ({ ...base, padding: 0 }),
    indicatorSeparator: base => ({ display: "none" }),
    indicatorsContainer: base => ({
      top: 1,
      position: "absolute",
      right: -16,
    }),
  }

  return (
    <InputStyled
      className={classnames(
        {
          fix: prefixComponent,
          fixIcon: iconName !== undefined,
          disabled,
        },
        className,
        type
      )}
    >
      {otherProps.label && (
        <label htmlFor={name} className={`label ${type}`}>
          <MarkdownText onClickLink={onClickLink}>
            {otherProps.label}
          </MarkdownText>
        </label>
      )}
      {type !== "textarea" ? (
        <div
          className={classnames("innerInput", type, {
            hasError: error && error.length > 0,
            hasBorder: hasBorder && type !== "checkbox",
          })}
        >
          <InputSwitcher
            type={type}
            computedType={computedType}
            name={name}
            options={options}
            disabled={disabled}
            placeholder={placeholder}
            selectStyles={selectStyles}
            onChangeInput={onChangeInput}
            onKeyDownInput={onKeyDownInput}
            onKeyUpInput={onKeyUpInput}
            autocomplete={autocomplete}
            otherProps={otherProps}
          />
          {otherProps.labelRight && (
            <label
              htmlFor={name}
              className={classnames("labelRight", type, labelRightClassName)}
              style={labelRightStyle}
            >
              {otherProps.labelRight}
            </label>
          )}
          {type === "password" && (
            <div className={"eye"} onClick={togglePwd}>
              <Icon name={iconPwd} />
            </div>
          )}
          {iconName && <Icon className={"innerIcon"} name={iconName} />}
        </div>
      ) : (
        <div
          className={classnames("innerInput", type, {
            hasError: error && error.length > 0,
            hasBorder: hasBorder && type !== "checkbox",
          })}
        >
          <textarea
            name={name}
            disabled={disabled}
            placeholder={placeholder}
            // onChange={event => onChangeInput(event.target.value)}
            onKeyDown={event => onKeyDownInput(event)}
            onKeyUp={event => onKeyUpInput(event)}
            rows="5"
            cols="50"
            {...otherProps}
          />
        </div>
      )}

      {prefixComponent && <span className={"prefix"}>{prefixComponent}</span>}

      <ErrorMsg style={errorStyle} msg={error} />
    </InputStyled>
  )
}

Input.propTypes = propTypes
Input.defaultProps = defaultProps

export default Input
