import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { _HOST_ } from "@env"

import Button from "@atoms/button"
import Copy from "@atoms/copy"

import { getUserID } from "@store/account/selectors"
import { getOptionsTenantToken } from "@store/options/selectors"
import { getTenantToken } from "@store/tenant/selectors"

import { subapps } from "@constants/enums"

import { subapp } from "@utils/functions"

import { FooterStyled } from "./footer.styled"

const isTour = subapp === subapps.TOUR

const Footer = () => {
  const { t } = useTranslation()
  const copyRef = useRef()

  const userID = useSelector(getUserID)
  const tenantToken = useSelector(getTenantToken)
  const tenantOptionsToken = useSelector(getOptionsTenantToken)
  const token = tenantToken || tenantOptionsToken

  const isAuthenticated = !!userID

  const addToClipboard = url => {
    copyRef && copyRef.current && copyRef.current.addToClipboard(url)
  }

  return (
    <FooterStyled>
      <div>@Hybreed {new Date().getFullYear()}</div>
      {isAuthenticated && !isTour && (
        <>
          <Button
            variant="link"
            onClick={() => addToClipboard(`${_HOST_}/contact?tenant=${token}`)}
          >
            {t("footer.contact")}
          </Button>
          <Copy ref={copyRef} />
        </>
      )}
    </FooterStyled>
  )
}

export default Footer
