import React, {
  forwardRef,
  useImperativeHandle,
  useRef
} from "react"
import { useTranslation } from "react-i18next"
import mergeRefs from "react-merge-refs"
import { useToasts } from "react-toast-notifications"

import { Holder } from "./copy.styled"

const { detect } = require("detect-browser")
const browser = detect()

const Copy = forwardRef(({ onSuccess, onError }, ref) => {
  const { addToast } = useToasts()
  const { t } = useTranslation()
  const localRef = useRef()

  const notify = (text, success) => {
    addToast(text, {
      appearance: success ? "success" : "error",
      autoDismiss: true,
    })
  }

  const addToClipboard = textToCopy => {
    switch (browser && browser.name) {
      case "chrome":
        navigator.permissions
          .query({ name: "clipboard-write" })
          .then(result => {
            if (result.state == "granted" || result.state == "prompt") {
              navigator.clipboard
                .writeText(textToCopy)
                .then(() => onSuccess || notify(t("toasts.copy_success"), true))
                .catch(() => onError || notify(t("toasts.copy_error"), false))
            }
          })
        break

      default:
        const holder = localRef.current
        holder.setAttribute("value", textToCopy)

        setTimeout(() => {
          holder.select()
          document.execCommand("copy")
          onSuccess || notify(t("toasts.copy_success"), true)
        }, 100)
        break
    }
  }

  useImperativeHandle(ref, () => {
    return {
      addToClipboard,
    }
  })

  return <Holder ref={mergeRefs([localRef, ref])} readOnly />
})

export default Copy
