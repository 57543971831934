import styled, { css } from "styled-components"

import { videoCallStyle } from "@dialogs/video-call/video-call.styled"

export const dialogStyle = css`
  background: hsla(0, 0%, 0%, 0.33);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const DialogStyled = styled.div`
  ${dialogStyle}

  &.visible {
    opacity: 1;
  }

  &.videoCall {
    ${videoCallStyle}
  }

  .topBar {
    position: absolute;
    top: -26px;
    right: 0px;
    bottom: 0px;
    height: 36px;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    button span {
      padding: 0;
      display: block;
      height: 30px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      border-radius: 0px;
    }

    .close {
      width: 26px;
      height: 26px;
      padding: 0;
      border-radius: 0px;
      background-color: ${({ theme }) => theme.palette.common.transparent};
      position: absolute;

      &:hover {
        background-color: ${({ theme }) => theme.palette.common.transparent};
      }

      .closeIcon {
        width: 100%;
        height: calc(100% + 1px);
      }
    }

    &.dark {
      background-color: ${({ theme }) => theme.palette.common.black};

      .close {
        top: 4px;
        right: 6px;

        .closeIcon {
          fill: ${({ theme }) => theme.palette.common.white};
        }
      }
    }

    &.light {
      background-color: ${({ theme }) => theme.palette.common.white};

      .close {
        top: 10px;
        right: 10px;

        .closeIcon {
          fill: ${({ theme }) => theme.palette.common.black};
        }
      }
    }
  }

  .card {
    @media (max-height: 400px) {
      transform: translateY(30%);
    }

    .content {
      position: relative;
      max-width: 95%;

      .button {
        /* margin-top: 20px; */
        /* width: 100%; */
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }

  .confirmCard {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &.scrollable {
    .topBar {
      top: -2px;
    }

    .content {
      max-height: 100%;
      overflow-y: auto;
      overflow-x: hidden;

      &.card {
        max-height: 90vh;
        margin: 0;
      }
    }
  }
`

export const DialogBottom = styled.div`
  display: flex;

  button + button {
    margin-left: 10px;
  }

  button {
    padding-top: 12px;
    padding-bottom: 12px;
  }
`
