import styled from "styled-components"

import { fontStyle } from "@styles/fonts"

export const NavigationItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${({ theme }) => theme.palette.common.greyDark};
  user-select: none;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s;
  height: ${({ theme }) => theme.sizes.heightHeader};

  .icon {
    width: ${({ theme }) => theme.sizes.heightHeader - 10};
    height: ${({ theme }) => theme.sizes.heightHeader - 10};
    color: ${({ theme }) => theme.palette.common.brownishGrey};
    position: absolute;
    top: -7px;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    transition: color 0.3s;
  }

  .title {
    width: 100%;
    ${fontStyle("small", "bolder")};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    color: ${({ theme }) => theme.palette.common.white};
    margin-top: 30px;
  }

  &:hover,
  &.selected {
    background-color: ${({ theme }) => theme.palette.primary.main};

    .icon {
      color: ${({ theme }) => theme.palette.common.white};
    }
  }
`
