import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useToasts } from "react-toast-notifications"

import { useMutation } from "@apollo/react-hooks"

import Dialog from "@atoms/dialog/dialog"

import Form from "@organisms/form"

import { closeDialog } from "@store/dialogs/actions"
import { getDialogs } from "@store/dialogs/selectors"
import { optionsSave } from "@store/options/actions"

import { languages, subapps } from "@constants/enums"
import { updateUserQ } from "@constants/queries/common"

import { LanguagesOptions } from "@utils/classes"
import { subapp } from "@utils/functions"

import { changeLanguage, getCurrentLanguage } from "@locales"

const DialogLanguages = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const dispatch = useDispatch()
  const _closeDialog = () => dispatch(closeDialog("languages"))
  const _optionsSave = data => dispatch(optionsSave(data))

  const { visible } = useSelector(getDialogs).languages

  const [updateUser, { loading }] = useMutation(updateUserQ)

  const initialValues = {
    language: { value: getCurrentLanguage() },
  }

  const submit = values => {
    const { language } = values

    _optionsSave({ language: language.value })
    changeLanguage(language.value)
    _closeDialog()
    addToast(t("toasts.updated_lng"), {
      appearance: "success",
      autoDismiss: true,
    })
  }

  // TODO: remove when TOUR ready for prod
  // start
  const isTour = subapp === subapps.TOUR
  if (isTour) {
    delete languages.en
    delete languages.de
    delete languages.pl
  }
  // end

  const { options } = new LanguagesOptions(languages)

  const fields = [
    {
      name: "language",
      type: "select",
      placeholder: t("forms.language.placeholder"),
      options,
      required: true,
      withStrength: true,
    },
    {
      type: "html",
      html: () => <div style={{ width: "100%", marginTop: 30 }}></div>,
    },
  ]

  return (
    <Dialog
      dialogKey={"languages"}
      visible={visible}
      title={t("dialogs.languages.title")}
      height={330}
      scrollable={false}
      content={
        <Form
          initialValues={initialValues}
          fields={fields}
          loading={loading}
          submitText={t("dialogs.languages.submit")}
          onSubmit={submit}
        />
      }
      classCard={"card"}
      visibleBottom={false}
    />
  )
}

export default DialogLanguages
