import styled from "styled-components"

export const CheckBoxStyle = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;

  .square {
    height: 16px;
    width: 16px;
    border-radius: 2px;
    position: relative;
    border: 1px solid ${({ theme }) => theme.palette.common.black};
    transition: background-color 0.2s, border-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;

    &.big {
      height: 20px;
      width: 20px;
    }

    .icon {
      color: ${({ theme }) => theme.palette.common.white};
      width: 10px;
      height: 10px;
      transition: opacity 0.2s, transform 0.2s;
    }
  }

  &.disabled {
    pointer-events: none;
  }

  input[type="checkbox"] {
    &:not(:checked),
    &:checked {
      display: none;
    }

    &:checked + .square {
      background-color: ${({ theme }) => theme.palette.accent.main};
      border-color: ${({ theme }) => theme.palette.accent.main};
    }

    &:not(:checked) + .square {
      .icon {
        opacity: 0;
        transform: scale(0);
      }
    }

    &:checked + .square {
      .icon {
        opacity: 1;
        transform: scale(1);
      }
    }

    &:disabled {
      & + .square {
        border-color: "#777";
        & + .label {
          color: "#999";
        }
      }

      &:checked + .square {
        border-color: "#777";
        background-color: "#777";
        & + .label {
          color: "#999";
        }
      }
    }
  }
`
