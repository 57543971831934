import { transparentize } from "polished"
import styled from "styled-components"

import { fontStyle } from "@styles/fonts"

export const NavigationTopStyled = styled.div`
  position: relative;
  width: 100%;
  z-index: 1000;

  .header {
    width: calc(100% - ${({ theme }) => theme.sizes.widthNavigation});
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.common.white};
    z-index: 50;
    height: ${({ theme }) => theme.sizes.heightHeader};
    right: 0;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletVertical}) {
      width: 100%;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
      box-shadow: 0 10px 10px 0 rgba(14, 37, 93, 0.08);
    }

    .text {
      width: ${({ theme }) => theme.sizes.heightHeader};
      height: ${({ theme }) => theme.sizes.heightHeader};
      background-color: ${({ theme }) => theme.palette.primary.main};
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 20px;

      @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
        margin-left: 10px;
      }
    }

    &.full {
      margin-left: 0px;
      width: 100% !important;

      &:not(.isPanel) {
        .logo {
          margin-left: 100px;
        }
      }
    }

    .wrap {
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        width: 31px;
        height: 32px;
        color: ${({ theme }) => theme.palette.common.black};
        cursor: pointer;
        margin-right: 8px;
        transform: scale(1.25);

        &:last-of-type {
          margin-right: 26px;
        }
      }

      .user {
        display: flex;
        flex-direction: row;
        align-items: center;

        .name {
          ${fontStyle("tiny")};
          color: ${({ theme }) => theme.palette.common.black};
          margin-left: 6px;
          text-transform: capitalize;

          .role {
            color: ${({ theme }) => theme.palette.common.grey};
            font-size: 10px;
            font-weight: normal;
          }
        }

        .buttonProfile {
          border-radius: 100px;
          padding: 4px;
          width: 30px;
          height: 30px;
          margin-left: 15px;
          margin-right: 0px;

          span {
            font-size: 16px;
            letter-spacing: 1px;
            font-weight: normal;
            line-height: 28px;
            color: ${({ theme }) =>
              transparentize(0.7, theme.palette.common.white)};
          }
        }

        .profileChevron {
          width: 24px;
          margin-left: 5px;
          margin-right: 20px;
          margin-top: 6px;
          cursor: pointer;
          g {
            fill: ${({ theme }) => theme.palette.common.grey};
          }
        }
      }
    }

    .notificationBox {
      position: absolute;
      top: 70px;
      right: 170px;
      z-index: 900;
    }

    .newnotifications {
      width: 9px;
      height: 9px;
      background-color: ${({ theme }) => theme.palette.common.red};
      border-radius: 8px;
      margin-right: -28px;
      margin-top: -12px;
      position: relative;
    }

    .dropdown {
      display: block;

      &.primary .itemText {
        color: ${({ theme }) => theme.palette.primary.main};
      }
    }

    .buttonNavigationTop {
      display: block;
      line-height: 0px;
      margin-left: 3px;
      margin-right: 20px;
      padding: 0px;
      position: relative;
      border-radius: 100px;
      width: 40px;
      height: 40px;

      svg {
        width: 30px;
        margin-right: 5px !important;
        margin-left: 5px !important;
      }

      .loader svg {
        margin: 0 auto !important;
      }
    }
  }
`
