import React from "react"

import classnames from "classnames"
import PropTypes from "prop-types"

import Icon from "@atoms/icon"

import { CheckBoxStyle } from "./checkbox.styled"

const propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
}

const defaultProps = {
  /** Default Props go here */
}

const Checkbox = ({
  disabled,
  bigVersion,
  name,
  handleCheckboxChange,
  placeholder,
  className,
  index = 0,
  ...otherProps
}) => {
  return (
    <CheckBoxStyle
      htmlFor={`checkbox_${name}${index}`}
      className={classnames({ disabled: disabled }, className)}
      {...otherProps}
    >
      <input
        type="checkbox"
        id={`checkbox_${name}${index}`}
        name={name}
        disabled={disabled}
        {...otherProps}
        onChange={
          handleCheckboxChange
            ? event => handleCheckboxChange(event, name)
            : undefined
        }
      />
      <div className={classnames("square", { big: bigVersion })}>
        <Icon className={"icon"} name="check" />
      </div>
    </CheckBoxStyle>
  )
}

Checkbox.propTypes = propTypes
Checkbox.defaultProps = defaultProps

export default Checkbox
