import "@reach/menu-button/styles.css"

import React, {
  useEffect,
  useRef,
  useState
} from "react"

import classnames from "classnames"
import { Link } from "gatsby"

import Icon from "@atoms/icon"

import { Menu, MenuButton, MenuItem, MenuList } from "@reach/menu-button"

import { DropdownStyled } from "./dropdown.styled"

const MenuCustomItem = ({ onSelect, value, ...otherProps }) => {
  return (
    <MenuItem
      className={"menuItem"}
      onSelect={e => onSelect(e, value)}
      {...otherProps}
    />
  )
}

const ItemContent = ({ text, subtext, icon, rightContent, textStyle }) => {
  return (
    <>
      {icon && <Icon name={icon} className="itemIcon" />}

      <div style={rightContent ? { maxWidth: 120 } : {}}>
        {text && (
          <span
            className={classnames("itemText", { hasIcon: !!icon })}
            style={textStyle}
          >
            {text}
          </span>
        )}
        {subtext && <span className={"subtext"}>{subtext}</span>}
      </div>
      {rightContent && <div className={"rightContent"}>{rightContent}</div>}
    </>
  )
}

const Dropdown = ({
  items,
  className,
  buttonClass,
  style,
  styleButton = {},
  styleDropdown = {},
  onClickGlobal,
  buttonProps = {},
  menuButton,
  matchButtonWidth,
  onSelect = () => null,
}) => {
  const buttonRef = useRef()
  const [buttonWidth, setButtonWidth] = useState(0)
  const computedStyle = matchButtonWidth
    ? { width: buttonWidth, ...styleDropdown }
    : { ...styleDropdown }

  useEffect(() => {
    if (!matchButtonWidth) return
    const { current } = buttonRef || {}
    const { _anchorEl } = current || {}
    const { offsetWidth = 0 } = _anchorEl || {}
    setButtonWidth(offsetWidth)
  }, [buttonRef.current])

  return (
    <DropdownStyled className={classnames(className)} style={style}>
      <Menu>
        <MenuButton
          // ref={matchButtonWidth && buttonRef}
          className={classnames("button", buttonClass)}
          onClick={e => {
            e.stopPropagation()
          }}
          style={styleButton}
          {...buttonProps}
        >
          {menuButton}
        </MenuButton>

        <MenuList
          portal={false}
          className={classnames("dropdown")}
          style={computedStyle}
        >
          {items
            .filter(({ visible }) => visible !== false)
            .map((item, i) => {
              const {
                text,
                subtext,
                rightContent,
                icon,
                to,
                onClick,
                textStyle = {},
                value,
              } = item
              return (
                <MenuCustomItem
                  key={i}
                  onSelect={() => {
                    onClick ? onClick() : onSelect && onSelect()
                    onClickGlobal && onClickGlobal()
                  }}
                  value={value}
                >
                  {to ? (
                    <Link to={to}>
                      <ItemContent
                        text={text}
                        icon={icon}
                        subtext={subtext}
                        rightContent={rightContent}
                        textStyle={textStyle}
                      />
                    </Link>
                  ) : (
                    <div>
                      <ItemContent
                        text={text}
                        icon={icon}
                        subtext={subtext}
                        rightContent={rightContent}
                        textStyle={textStyle}
                      />
                    </div>
                  )}
                </MenuCustomItem>
              )
            })}
        </MenuList>
      </Menu>
    </DropdownStyled>
  )
}

export default Dropdown
