import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import fetcher from "@store/fetcher"

export const Fetch = ({ children, path, options, onSuccess, onError }) => {
  const dispatch = useDispatch()
  const _fetcher = (path, options, callbacks) =>
    dispatch(fetcher(path, options, callbacks))

  const execute = () => {
    _fetcher(path, options, {
      success: onSuccess,
      error: onError,
    })
  }

  useEffect(() => {
    execute()
  }, [])

  return <>{children}</>
}
