import "@styles/layout.css"

import React, {
  useEffect,
  useState
} from "react"
import { useDispatch, useSelector } from "react-redux"

import classnames from "classnames"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import socketIOClient from "socket.io-client"
import styled from "styled-components"
import { useDebounce } from "use-debounce"

import { useLazyQuery } from "@apollo/react-hooks"

import sound from "@assets/sounds/beep.mp3"

import { _API_URL_ } from "@env"

import Spinner from "@atoms/spinner"

import Footer from "@organisms/footer"
import NavigationSide from "@organisms/navigation-side"
import NavigationTop from "@organisms/navigation-top"

import DialogLanguages from "@dialogs/languages"
import DialogPwdEdit from "@dialogs/pwd-edit"

import { Fetch } from "@services/Fetch"

import { userUpdate } from "@store/account/actions"
import { getUser, getUserToken } from "@store/account/selectors"
import { anyDialogOpen } from "@store/dialogs/selectors"
import { _HYBREED_URL_ } from "@store/endpoints"
import { eventsTrigger } from "@store/events/actions"
import fetcher from "@store/fetcher"
import { getOpTrigger } from "@store/operators/selectors"
import { optionsSave } from "@store/options/actions"
import { getOptionsLanguage } from "@store/options/selectors"
import { requestHighlight, requestsTrigger } from "@store/requests/actions"
import { getRequestsMode } from "@store/requests/selectors"
import { tenantUpdate } from "@store/tenant/actions"
import { getTenantToken, getTenant } from "@store/tenant/selectors"

import { modes, subapps } from "@constants/enums"
import { getUserQ } from "@constants/queries/common"

import { getQueryParam, subapp } from "@utils/functions"

import { i18nInit } from "../locales"
const isWindow = typeof window !== "undefined"
const UIfx = isWindow ? require("uifx").default : null
const beep = UIfx ? new UIfx(sound) : null

i18nInit()

let teams = []

const isDev = process.env.NODE_ENV === "development"

const LayoutStyled = styled.div`
  .body {
    width: 100%;
    min-height: 100vh;
    background-color: ${({ theme }) => theme.palette.background.main};
    font-family: "Open Sans";
    padding-bottom: 160px;
    position: relative;
    z-index: 100;

    &.sideLayout {
      min-height: 110vh;
      padding-left: ${({ theme }) => theme.sizes.widthNavigation};

      @media (max-width: ${({ theme }) => theme.breakpoints.tabletVertical}) {
        padding-left: 0px;
      }
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
      &:not(.sideLayout) {
        background-color: ${({ theme }) => theme.palette.common.white};
      }
    }

    &.blur {
    }

    .innerBody {
      padding: 20px 40px;
      padding-top: calc(${({ theme }) => theme.sizes.heightHeader} + 30px);
      animation: ${({ theme }) => theme.animations.fadeIn} 0.5s forwards;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        padding: 20px 30px;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: calc(
          ${({ theme }) => theme.sizes.heightHeader} + 10px
        ) !important;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        width: 100%;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .profile {
      z-index: 70;
    }

    .content {
      margin-top: ${({ theme }) => theme.sizes.heightHeader};
      width: 100%;
      position: relative;
    }
  }

  .flex {
    display: flex;
    flex-wrap: wrap;

    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
      margin-top: 20px;
    }

    .detail {
      line-height: 18px;
      flex: 1;

      @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
        max-width: 100%;
        width: 100%;
        flex: none;
        margin-top: 10px;
      }

      p {
        font-size: 12px;
      }
      b {
        font-size: 14px;
        color: ${({ theme }) => theme.palette.common.black};
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
          max-width: 160px;
        }
      }

      p {
        margin-bottom: 2px;
        color: ${({ theme }) => theme.palette.common.brownGrey};
      }
    }
  }
`

if (typeof window !== "undefined") window.socketSessionStarted = false

const Layout = props => {
  const { children, className, error } = props

  const [fullLoading, setFullLoading] = useState(true)

  const user = useSelector(getUser)
  const token = useSelector(getUserToken)
  const isAnyDialogOpen = useSelector(anyDialogOpen)
  const mode = useSelector(getRequestsMode)
  const tenantToken = useSelector(getTenantToken)
  const opTrigger = useSelector(getOpTrigger)
  const optLng = useSelector(getOptionsLanguage)
  const [debouncedTenantToken] = useDebounce(tenantToken, 400)

  const tenantQueryToken =
    getQueryParam("token", "") || getQueryParam("tenant", "")
  const roomToken = getQueryParam("roomToken")

  // QUERIES

  const dispatch = useDispatch()
  const _userUpdate = data => dispatch(userUpdate(data))
  const _tenantUpdate = data => dispatch(tenantUpdate(data))
  const _optionsSave = data => dispatch(optionsSave(data))
  const _fetcher = (path, options, callbacks) =>
    dispatch(fetcher(path, options, callbacks))

  const _requestsTrigger = () => dispatch(requestsTrigger())
  const _requestHighlight = id => dispatch(requestHighlight(id))
  const _eventsTrigger = () => dispatch(eventsTrigger())

  const isAuthenticated = !!user.id
  const isPanel = isAuthenticated && !!user.activeLayout
  const isTour = subapp === subapps.TOUR

  const [getUserData, { data: userData }] = useLazyQuery(getUserQ, {
    fetchPolicy: "no-cache",
    variables: {
      operatorId: user.id,
    },
  })

  const onFetchOptions = {
    success: data => {
      _optionsSave({ ...data })
      const finalTenantToken =
        tenantQueryToken || tenantToken || data.defaultTenant || ""
      if (!isAuthenticated) fetchTenant(finalTenantToken)
    },
  }

  const onSocketConnection = socketTeams => {
    teams = socketTeams
  }

  const onSocketMessage = res => {
    console.log("[socket message received]", res)
    const data = JSON.parse(res)
    const {
      REQUEST_UPDATED_DIRECT,
      REQUEST_UPDATED_SCHEDULED,
      REQUEST_UPDATED_EVENT,
      id,
      op,
    } = data || {}
    const key =
      REQUEST_UPDATED_DIRECT ||
      REQUEST_UPDATED_SCHEDULED ||
      REQUEST_UPDATED_EVENT
    const isModeScheduled = mode === modes.SCHEDULED
    const isModeLive = mode === modes.LIVE

    const isInTeam = teams.indexOf(key) > -1

    if (isInTeam) {
      if (
        (REQUEST_UPDATED_DIRECT && isModeLive) ||
        (REQUEST_UPDATED_SCHEDULED && isModeScheduled)
      ) {
        _requestsTrigger()
        id && _requestHighlight(id)
        if (op === "create") {
          beep.play()
        }
      } else if (REQUEST_UPDATED_EVENT && isTour) {
        _eventsTrigger()
      }
    }
  }

  const checkUser = () => {
    if (typeof window === "undefined") return
    const isWelcome = window.location.pathname.includes("/welcome")
    const canInit = token && isAuthenticated && !isWelcome

    if (window.socketSessionStarted !== true) {
      if (canInit) {
        window.socketSessionStarted = true

        window.socket = socketIOClient(_HYBREED_URL_, {
          query: {
            accessToken: token,
          },
        })

        window.socket.connect()
        window.socket.on("JOINED_ROOMS", onSocketConnection)
        window.socket.on("message", onSocketMessage)

        if (isDev) {
          console.log("[socket instance created]")
          window.socket.on("disconnect", e => {
            console.log("[socket disconnected]", e)
          })
          window.socket.on("reconnect_attempt", () => {
            console.log("[socket reconnect attempt]")
          })
          window.socket.on("error", error => {
            console.log("[socket error]", error)
          })
          window.socket.on("connect_error", error => {
            console.log("[socket connect_error]", error)
          })
          window.socket.on("reconnect_error", error => {
            console.log("[socket error]", error)
          })
          window.socket.on("reconnect_failed", () => {
            console.log("[socket reconnect_failed]")
          })
          window.socket.on("ping", () => {
            console.log("[socket ping]")
          })
        }
      } else if (window.socket) {
        window.socket.disconnect()
      }
    }

    setFullLoading(true)

    if (canInit) {
      getUserData()
    }
  }

  const onFetchTenant = {
    success: ({ tenant = {}, teams = [] }) => {
      _tenantUpdate({ ...tenant, teams })
      setFullLoading(false)
    },
    error: () => {
      setFullLoading(false)
    },
  }

  const fetchTenant = tkn => {
    if (roomToken) return
    const options = {
      params: { token: tkn || finalTenantToken },
    }

    _fetcher("GET.tenant", options, onFetchTenant)
  }

  useEffect(() => {
    if (userData) {
      const { allUsers = [] } = userData
      if (allUsers && allUsers[0]) {
        const [me] = allUsers
        const { role } = me
        if (!role) {
          navigate("/logout")
          return
        }

        const { tenant } = me || {}
        const { accessToken: tkn } = tenant || {}
        _tenantUpdate({ ...tenant })

        fetchTenant(tkn)
        // delete me.tenant
        _userUpdate({
          ...me,
          tenant: undefined,
        })
        if (!optLng) _optionsSave({ language: me.language }) // if language hasn't yet been setted in options, set it for the first user fetch; this is useful to avoid browser language and user language mismatches
        i18nInit().then(() => {})
      }
    }
  }, [userData])

  useEffect(() => {
    setTimeout(() => checkUser(), 100)
  }, [isAuthenticated, token, opTrigger])

  useEffect(() => {
    if (typeof window !== "undefined" && window.socket) {
      window.socket.on("message", onSocketMessage)
    }
  }, [mode])

  const sideLayout = isPanel && !isTour

  return (
    <>
      <Fetch path="GET.options" onSuccess={onFetchOptions.success} />

      <LayoutStyled className={classnames("layout")}>
        {sideLayout && <NavigationSide />}
        <NavigationTop full={!sideLayout} isPanel={isPanel} user={user} />

        <div
          className={classnames("body", className, {
            full: !isPanel,
            blur: isAnyDialogOpen,
            sideLayout,
          })}
        >
          <div className={"innerBody"}>
            <main>{children}</main>
            <Footer />
          </div>
        </div>

        <DialogPwdEdit />
        <DialogLanguages />
        {!debouncedTenantToken && <Spinner fullpage hidden={!fullLoading} />}
      </LayoutStyled>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
