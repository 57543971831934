import styled from "styled-components"

import { fontStyle } from "@styles/fonts"

export const DropdownStyled = styled.div`
  [data-reach-menu] {
    right: 0;
    z-index: 10000;
  }

  outline: none;

  .button {
    background-color: ${({ theme }) => theme.palette.common.white};
    border: none;
    padding-right: 0px;
    margin: 0px;
    cursor: pointer;
    outline: none;
    margin-top: -2px;
  }

  .dropdown {
    box-shadow: 0 10px 10px 0 rgba(14, 37, 93, 0.08) !important;
    border-radius: 10px !important;
    margin-top: 10px;
    margin-right: 10px;
    padding: 0px !important;
    overflow: hidden;
    border: 1px solid ${({ theme }) => theme.palette.common.gainsboro};
  }

  .menuItem {
    cursor: pointer;
    user-select: none;
    display: block;
    z-index: 1000;
    padding: 0px;
    position: relative;
    margin-bottom: -1px;
    transition: 0.3s background-color;

    svg.itemIcon {
      position: absolute;
      top: 2px;
      left: 10px;
      width: 30px;
    }

    &[data-selected] {
      background-color: ${({ theme }) => theme.palette.common.paleGrey};
    }

    & > a,
    & > div {
      text-decoration: none;
      z-index: 1001;
      padding: 11px 18px;
      text-align: left;
      display: block;
      line-height: normal;

      span {
        display: block;
      }
    }

    & > div {
      display: flex;
      justify-content: space-between;
      &:hover div {
        color: black;
      }
    }

    &:not(:last-of-type) {
      &:after {
        content: "";
        display: block;
        width: 85%;
        height: 1px;
        background-color: ${({ theme }) => theme.palette.common.gainsboro};
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .itemText {
    color: ${({ theme }) => theme.palette.common.black};
    ${fontStyle("small", "bold")};
    margin-bottom: 0px;
    min-width: 150px;

    &.hasIcon {
      margin-left: 24px;
    }
  }

  .subtext {
    color: ${({ theme }) => theme.palette.common.greyDark};
    display: block;
    ${fontStyle("tiny")};

    min-width: 100px;
  }

  .rightContent {
    color: ${({ theme }) => theme.palette.common.black};
    display: block;
    max-width: 80px;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      width: 18px;
      margin-left: 10px;
    }
  }
`
