import React from "react"
import { useTranslation } from "react-i18next"

import PropTypes from "prop-types"

import { _API_URL_ } from "@env"

import NavigationItem from "@molecules/navigation-item"

import { subapp } from "@utils/functions"

import { NavigationSideStyled } from "./navigation-side.styled"

const propTypes = {
  /** Proptypes go here */
  username: PropTypes.string,
}

const defaultProps = {
  /** Default Props go here */
}

const NavigationSide = ({
  username,
  onClickProfile,
  onClickLogout,
  className,
  currentBaseRoot,
  ...otherProps
}) => {
  const { t } = useTranslation()

  const menuItems = t("navigationSide", { returnObjects: true })

  return (
    <NavigationSideStyled className={"blallo"} {...otherProps}>
      <div className={"wrap"}>
        <div>
          {menuItems.map((m, i) => {
            const name = m.toLowerCase()
            const to = `https://${name}.hybreed.it/app`

            const selected = subapp === name

            return (
              <a key={i} href={selected ? _API_URL_ : to}>
                <NavigationItem
                  iconName={name}
                  title={name}
                  selected={selected}
                />
              </a>
            )
          })}
        </div>
      </div>
    </NavigationSideStyled>
  )
}

NavigationSide.propTypes = propTypes
NavigationSide.defaultProps = defaultProps

export default NavigationSide
