import React from "react"

import classnames from "classnames"

import Icon from "@atoms/icon"
import Spinner from "@atoms/spinner"

import { CardSeparatorStyled, CardStyled } from "./card.styled"

const Card = ({
  full,
  children,
  className,
  classTitle,
  loading,
  title,
  titleComponent,
  icon,
  style,
  circleVariant,
  actionsButtonChildren,
  smallTitle,
  headSeparator,
}) => {
  return (
    <CardStyled className={classnames({ full: full }, className)} style={style}>
      <Spinner hidden={!loading} />
      {!loading && (
        <>
          {icon && (
            <>
              <div className={classnames("iconCircle", circleVariant)}>
                <Icon className={"icon"} name={icon} />
              </div>
              <br></br>
              <br></br>
            </>
          )}
          {(title || titleComponent) && (
            <h3
              className={classnames("title", classTitle, {
                withActions: actionsButtonChildren,
                smallTitle: smallTitle,
              })}
            >
              {titleComponent ? titleComponent : title}
            </h3>
          )}
          <div className={"actionsButtonChildren"}>{actionsButtonChildren}</div>
          {headSeparator ? <CardSeparatorStyled /> : null}
          {children}
        </>
      )}
    </CardStyled>
  )
}

export default Card
