import React from "react"

import classnames from "classnames"
import { Link } from "gatsby"

import { HeaderNavigationStyled } from "./header-navigation.styled"

const HeaderNavigation = ({ menu }) => {
  const { location } = window || {}
  const { pathname } = location || {}

  return (
    <HeaderNavigationStyled>
      {menu.map(({ text, to }, i) => {
        const isActive = pathname.includes(to)
        return (
          <Link
            key={i}
            to={to}
            className={classnames("headerNavigationItem", {
              active: isActive,
            })}
          >
            {text}
          </Link>
        )
      })}
    </HeaderNavigationStyled>
  )
}

export default HeaderNavigation
