import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useToasts } from "react-toast-notifications"

import classnames from "classnames"
import faker from "faker"

import Button from "@atoms/button"
import Copy from "@atoms/copy"
import HeaderNavigation from "@atoms/header-navigation"
import Icon from "@atoms/icon"
import Logo from "@atoms/logo"

import Dropdown from "@organisms/dropdown"

import { openDialog } from "@store/dialogs/actions"
import fetcher from "@store/fetcher"
import { getFetch } from "@store/fetcher/selectors"
import { getTenant, getTenantID } from "@store/tenant/selectors"

import { languages, roles } from "@constants/enums"

import { getTimeZoneCode } from "@utils/dayjsLocale"
import { subapp } from "@utils/functions"

import { getCurrentLanguage } from "@locales"

import { NavigationTopStyled } from "./navigation-top.styled"

const { _HOST_, _TOKEN_CONTACT_ } = require("@env")
const currentTimeZone = getTimeZoneCode()

const NavigationTop = ({ full, isPanel, user, className }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const copyRef = useRef()

  const currentLang = getCurrentLanguage()

  const { instantCallFeature, bookingFeature } = useSelector(getTenant)
  const { isLoading } = useSelector(state => getFetch(state, "POST.contact"))

  const tenant = useSelector(getTenantID)

  const dispatch = useDispatch()
  const _openDialog = key => dispatch(openDialog(key))
  const _fetcher = (path, options, callbacks) =>
    dispatch(fetcher(path, options, callbacks))

  const openpwdEdit = () => {
    _openDialog("pwdEdit")
  }

  const openReservation = () => {
    _openDialog("room")
  }

  const openLanguages = () => {
    _openDialog("languages")
  }

  const openTimezones = () => {
    _openDialog("timezones")
  }

  const getInitialName = fullname => {
    const splitted = fullname.split(" ")
    const [name, surname] = splitted
    return splitted.length >= 2
      ? name.charAt(0) + surname.charAt(0)
      : name.charAt(0)
  }

  const { id, name, role, surname, email, teams } = user || {}
  const [firstTeam] = teams || []
  const { id: team } = firstTeam || {}

  const isManager = role === roles.MANAGER

  const { location } = window || {}
  const { pathname } = location || {}
  const isRequestsPage = pathname.includes("/requests")

  const addToClipboard = videoUrl => {
    copyRef && copyRef.current && copyRef.current.addToClipboard(videoUrl)
  }

  const onFetchContact = {
    success: ({ accessToken }) => {
      addToClipboard(`${_HOST_}/contact?accessToken=${accessToken}`)
    },
    error: () => {
      notify(t("errors.GraphQLError"), false)
    },
  }

  const createDirect = () => {
    const email = `anonymous-${faker.internet.email()}`

    const options = {
      headers: { Authorization: `Bearer ${_TOKEN_CONTACT_}` },
      body: {
        clientId: email,
        fullname: t(`${subapp}.navigationTop.direct_mock.fullname`),
        email,
        team,
        topic: t(`${subapp}.navigationTop.direct_mock.topic`),
        tenant,
        scheduledStartsAt: null,
      },
    }

    _fetcher("POST.contact", options, onFetchContact)
  }

  const notify = (text, success) => {
    addToast(text, {
      appearance: success ? "success" : "error",
      autoDismiss: true,
    })
  }

  const createMenu = [
    {
      text: t(`${subapp}.navigationTop.create.direct`),
      icon: "call",
      visible: !!instantCallFeature,
      onClick: createDirect,
    },
    {
      text: t(`${subapp}.navigationTop.create.scheduled`),
      icon: "calendar-new",
      visible: !!bookingFeature,
      onClick: openReservation,
    },
  ]

  const settingsMenu = [
    {
      text: t("navigationTop.settings.language"),
      rightContent: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span> {languages[currentLang].short}</span>{" "}
          <Icon name={`lang-${currentLang}`} />
        </div>
      ),
      onClick: openLanguages,
    },
    // {
    //   text: t("navigationTop.settings.timezone"),
    //   rightContent: (
    //     <div style={{ display: "flex", alignItems: "center" }}>
    //       <span> {currentTimeZone}</span>
    //     </div>
    //   ),
    //   onClick: openTimezones,
    // },
  ]

  const profileMenu = [
    {
      text: t("navigationTop.profile.email"),
      subtext: email,
      // to: "/account/edit/email",
    },
    { text: t("navigationTop.profile.edit_pwd"), onClick: openpwdEdit },
    {
      text: t("navigationTop.profile.logout"),
      to: "/logout",
      textStyle: { color: "red" },
    },
  ]

  const menu = t(`${subapp}.menu`, {
    returnObjects: true,
  })
  const menuKeys = Object.keys(menu)
  const menuValues = Object.values(menu)

  const navigationMenu = menuValues.map((item, i) => ({
    text: item,
    to: `/${menuKeys[i]}`,
  }))

  return (
    <>
      <NavigationTopStyled>
        <div
          className={classnames("header", className, {
            full,
            isPanel,
          })}
        >
          <div className={"wrap"}>
            <Logo full={full} className={"logo"} />
            {isManager && <HeaderNavigation menu={navigationMenu} />}
          </div>

          <div className={"wrap"}>
            <>
              <div className={"user"}>
                {isRequestsPage && (!!instantCallFeature || !!bookingFeature) && (
                  <div style={{ position: "relative" }}>
                    <Dropdown
                      items={createMenu}
                      className={"dropdown primary"}
                      buttonProps={{
                        as: Button,
                        variant: "primary",
                        className: "buttonNavigationTop",
                        icon: "call-new",
                        loading: isLoading,
                        disabled: isLoading,
                      }}
                    />
                    <Copy ref={copyRef} />
                  </div>
                )}

                <Dropdown
                  items={settingsMenu}
                  className={"dropdown"}
                  style={{ marginLeft: -10 }}
                  buttonProps={{
                    as: Button,
                    variant: "grey",
                    className: "buttonNavigationTop",
                    icon: "settings",
                  }}
                />

                {(name || surname) && user.activeLayout && (
                  <>
                    {role && (
                      <div className={"name"}>
                        {`${name} ${getInitialName(surname)}`}.<br></br>
                        <span className={"role"}>{t(`roles.${role}`)}</span>
                      </div>
                    )}
                    <Dropdown
                      items={profileMenu}
                      buttonProps={{
                        as: Button,
                        variant: "primary",
                        className: "buttonProfile",
                      }}
                      menuButton={<>{getInitialName(`${name} ${surname}`)} </>}
                    />
                    <Dropdown
                      items={profileMenu}
                      buttonProps={{
                        as: Icon,
                        className: "profileChevron",
                        name: "chevron-down",
                      }}
                    />
                  </>
                )}
              </div>
            </>
          </div>
        </div>
      </NavigationTopStyled>
    </>
  )
}

export default NavigationTop
