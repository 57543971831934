import styled from "styled-components"

import { fontStyle } from "@styles/fonts"

export const FooterStyled = styled.footer`
  display: flex;
  text-align: center;
  flex-direction: row;
  width: 400px;
  max-width: 100%;
  flex-wrap: wrap;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  margin-top: -30px;
  z-index: 100;

  div {
    margin: 0 auto;
    ${fontStyle("small")};

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      flex-basis: 50%;
      margin-top: 10px;
      width: auto;
    }
  }

  div,
  a,
  button {
    color: ${({ theme }) => theme.palette.common.brownGrey};
    text-decoration: none;
  }

  button > span {
    display: block;
  }
`
