import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react"
import { useModal } from "react-modal-hook"
import { useDispatch, useSelector } from "react-redux"

import classnames from "classnames"

import Button from "@atoms/button"
import Card from "@atoms/card"
import Icon from "@atoms/icon"
import Spinner from "@atoms/spinner"

import { closeDialog, setDialogData } from "@store/dialogs/actions"
import { getDialogs } from "@store/dialogs/selectors"

import { DialogBottom, DialogStyled } from "./dialog.styled"

const Confirm = ({ confirmTitle, confirmContent }) => {
  return (
    <DialogStyled>
      <Card title={confirmTitle} className={"confirmCard"}>
        {confirmContent}
      </Card>
    </DialogStyled>
  )
}

const Topbar = ({ mode, onDialogClose }) => {
  return (
    <div className={classnames("topBar", mode)}>
      <Button className={"close"} onClick={onDialogClose}>
        <Icon className={"closeIcon"} name="close" />
      </Button>
    </div>
  )
}

const BottomSection = ({ close, buttons }) => {
  return (
    <DialogBottom>
      {buttons &&
        buttons.length &&
        buttons.map(
          ({ text, variant = "primary", onClick = close, style = {} }) => (
            <Button
              full
              className={"button"}
              variant={variant}
              onClick={onClick}
              style={style}
            >
              {text}
            </Button>
          )
        )}
    </DialogBottom>
  )
}

const Dialog = forwardRef(
  (
    {
      dialogKey,
      title,
      content,
      confirmTitle,
      confirmContent,
      loading,
      width,
      height,
      visible,
      visibleBottom = true,
      visibleClose: vC = true,
      buttons = [],
      topBarMode = "light",
      scrollable = true,
      style = {},
      onClose,
      onSubmit,
      className,
      classCard,
      classTitle,
      classOverlay,
    },
    ref
  ) => {
    const dispatch = useDispatch()
    const _closeDialog = () => dispatch(closeDialog(dialogKey))
    const _setDialogData = data => dispatch(setDialogData(dialogKey, data))

    const { onClose: onCloseRedux } = data => useSelector(getDialogs)

    const [visibleTransition, setVisibleTransition] = useState(false)
    const [visibleConfirm, setVisibleConfirm] = useState(false)
    const [visibleClose, setVisibleClose] = useState(vC)

    const close = submit => {
      setTimeout(() => {
        _setDialogData(null)
        onClose && onClose()
        onCloseRedux && onCloseRedux()
        if (submit) onSubmit && onSubmit()
      }, 100)
      _closeDialog()
    }

    const onDialogClose = () =>
      confirmContent ? setVisibleConfirm(true) : close()

    useImperativeHandle(ref, () => {
      return {
        onDialogClose,
        setVisibleConfirm,
        setVisibleClose,
      }
    })

    const onKeyDown = e => {
      const { key } = e
      if (key === "Escape") close()
    }

    const [showModal, hideModal] = useModal(
      () => (
        <DialogStyled
          className={classnames(className, {
            visible: visibleTransition,
            scrollable,
          })}
        >
          <Card
            title={title}
            className={classnames("content", classCard)}
            classTitle={classTitle}
            style={{ height, width, ...style }}
          >
            <Spinner hidden={!loading} />
            {!loading && (
              <>
                {visibleConfirm && (
                  <Confirm
                    confirmTitle={confirmTitle}
                    confirmContent={confirmContent}
                  />
                )}
                {visibleClose && (
                  <Topbar mode={topBarMode} onDialogClose={onDialogClose} />
                )}
                {content}
                {visibleBottom && buttons && (
                  <>
                    <br></br>
                    <BottomSection
                      buttons={buttons}
                      close={() => close(true)}
                    />
                  </>
                )}
              </>
            )}
          </Card>
        </DialogStyled>
      ),
      [content, visibleTransition, visibleClose, visibleConfirm]
    )

    useEffect(() => {
      visible ? showModal() : hideModal()
      setVisibleTransition(visible)
      // setTimeout(() => {
      //   setVisibleTransition(visible)
      // }, 100)
      if (visible) document.addEventListener("keydown", onKeyDown, false)
      return () => document.removeEventListener("keydown", onKeyDown, false)
    }, [visible])

    useEffect(() => {
      return () => document.removeEventListener("keydown", onKeyDown, false)
    }, [])

    return null
  }
)

export default Dialog
