import styled from "styled-components"

export const NavigationSideStyled = styled.div`
  width: ${({ theme }) => theme.sizes.widthNavigation};

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVertical}) {
    display: none;
  }

  .wrap {
    position: fixed;
    width: ${({ theme }) => theme.sizes.widthNavigation};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.palette.common.black};
    z-index: 102;

    a {
      text-decoration: none;
      text-transform: capitalize;
    }

    .button {
      cursor: pointer;

      svg,
      svg path {
        color: ${({ theme }) => theme.palette.common.white};
      }
    }

    .activeRoute {
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`
