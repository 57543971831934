import { gql } from "apollo-boost"

// -------------------- TEAMS --------------------

export const createTeamQ = gql`
  mutation createTeam($data: TeamCreateInput) {
    createTeam(data: $data) {
      id
    }
  }
`

export const updateTeamQ = gql`
  mutation updateTeam($id: ID!, $data: TeamUpdateInput) {
    updateTeam(id: $id, data: $data) {
      id
    }
  }
`

export const deleteTeamQ = gql`
  mutation updateTeam($id: ID!) {
    updateTeam(id: $id, data: { visibility: deleted }) {
      id
    }
  }
`

export const createWorkingShiftQ = gql`
  mutation createWorkingShift($data: WorkingShiftCreateInput) {
    createWorkingShift(data: $data) {
      id
    }
  }
`

export const updateWorkingShiftQ = gql`
  mutation updateWorkingShift($id: ID!, $data: WorkingShiftUpdateInput) {
    updateWorkingShift(id: $id, data: $data) {
      id
    }
  }
`

export const createWorkingShiftDaysQ = gql`
  mutation createWorkingShiftDays($data: [WorkingShiftDaysCreateInput]) {
    createWorkingShiftDays(data: $data) {
      id
    }
  }
`

export const updateWorkingShiftDaysQ = gql`
  mutation updateWorkingShiftDays($data: [WorkingShiftDaysUpdateInput]) {
    updateWorkingShiftDays(data: $data) {
      id
    }
  }
`

export const deleteWorkingShiftQ = gql`
  mutation deleteWorkingShift($id: ID!) {
    deleteWorkingShift(id: $id) {
      id
    }
  }
`

export const deleteWorkingShiftDaysQ = gql`
  mutation deleteWorkingShiftDays($id: [ID!]) {
    deleteWorkingShiftDays(id: $id) {
      id
    }
  }
`

// -------------------- CALENDAR DAYS --------------------
export const getCalendarDayOfRequestQ = gql`
  query getCalendarDayOfRequest($id: ID!) {
    allCalendarDays(where: { appointments_some: { id: $id } }) {
      id
      appointments {
        id
      }
    }
  }
`

export const updateCalendarDayQ = gql`
  mutation calendarDay($id: ID!, $appointmentsIDs: [RequestWhereUniqueInput]) {
    updateCalendarDay(
      id: $id
      data: { appointments: { disconnectAll: true, connect: $appointmentsIDs } }
    ) {
      id
    }
  }
`

// -------------------- USER --------------------

export const getUsersQ = gql`
  query getUsers($teamNot: ID, $tenant: ID) {
    allUsers(
      where: {
        role_not: null
        visibility: active
        teams_none: { id: $teamNot }
        tenant: { id: $tenant }
      }
    ) {
      id
      name
      surname
      email
      role
      visibility
      teams {
        id
      }
    }
  }
`

export const getUserQ = gql`
  query getUser($operatorId: ID) {
    allUsers(where: { id: $operatorId }) {
      id
      name
      surname
      email
      status
      role
      language
      tenant {
        id
        accessToken
        brandColorPrimary
        brandColorAccent
        logo {
          publicUrl
        }
      }
      teams {
        id
        tag
        name
      }
    }
  }
`

export const createUserQ = gql`
  mutation createUser($data: UserCreateInput) {
    createUser(data: $data) {
      id
    }
  }
`

export const updateUserQ = gql`
  mutation updateUser($id: ID!, $data: UserUpdateInput) {
    updateUser(id: $id, data: $data) {
      id
      name
      surname
      email
      status
      teams {
        id
        tag
      }
    }
  }
`

export const assignUserQ = gql`
  mutation($user: ID!, $team: ID!) {
    addUserToTeam(user: $user, team: $team)
  }
`

export const deleteUserQ = gql`
  mutation user($id: ID!) {
    updateUser(id: $id, data: { visibility: deleted }) {
      id
    }
  }
`

// -------------------- WORKING SHIFTS --------------------
export const getWorkingShiftsQ = gql`
  query getWorkingShifts(
    $team: ID
    $tenant: ID
    $n: Int
    $today: DateTime
    $orderBy: String
  ) {
    allWorkingShifts(
      first: $n
      orderBy: $orderBy
      where: {
        AND: [
          { teams: { id: $team } }
          { tenant: { id: $tenant } }
          { visibility: active }
          { validUntil_gte: $today }
        ]
      }
    ) {
      id
      validFrom
      validUntil
      countryHolidays
      fte
      appointmentDuration
      teams {
        id
      }
      workingShiftDays(orderBy: "weekDay_ASC") {
        id
        weekDay
        startTime
        breakStartTime
        breakEndTime
        endTime
      }
    }
    _allWorkingShiftsMeta(
      where: {
        teams: { id: $team }
        tenant: { id: $tenant }
        visibility: active
        validUntil_gte: $today
      }
    ) {
      count
    }
  }
`
