export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const alphaNumericRegex = /^[a-zA-Z0-9]+$/
export const alphaRegex = /^[a-zA-Z]+$/
export const numRegex = /([0-9])+/
export const nameRegex = /^[a-zA-Z ]+$/
export const phoneRegex = /^[0-9\(\)\-+ ]+$/
export const passwordRegex = /^(([0-9])|([A-z])|([^((0-9)|(a-z)|(A-Z)|\s)]))+$/
// Must have either capitals and lowercase letters or lowercase and numbers
export const passwordStrengthMedium = /^(?=.{8,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$/
// Must have capital letter, numbers and lowercase letters
export const passwordStrengthHigh = /^.*(?=.{8,})((?=.*[^((0-9)|(a-z)|(A-Z)|\s)]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/
