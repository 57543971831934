import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useToasts } from "react-toast-notifications"

import { useMutation } from "@apollo/react-hooks"

import Dialog from "@atoms/dialog/dialog"

import Form from "@organisms/form"

import { getUser } from "@store/account/selectors"
import { closeDialog } from "@store/dialogs/actions"
import { getDialogs } from "@store/dialogs/selectors"

import { updateUserQ } from "@constants/queries/common"

const DialogPwdEdit = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const { visible } = useSelector(getDialogs).pwdEdit
  const user = useSelector(getUser)

  const { id } = user

  const dispatch = useDispatch()
  const _closeDialog = () => dispatch(closeDialog("pwdEdit"))

  const [updateUser, { loading }] = useMutation(updateUserQ)

  const initialValues = {
    password: "",
  }

  const submit = values => {
    const { password } = values

    updateUser({
      fetchPolicy: "no-cache",
      variables: {
        id,
        data: { password },
      },
    }).then(() => {
      addToast(t("toasts.updated_pwd"), {
        appearance: "success",
        autoDismiss: true,
      })
    })
  }

  useEffect(() => {
    if (visible && !loading) _closeDialog()
  }, [loading])

  const fields = [
    {
      name: "password",
      type: "password",
      required: true,
      withStrength: true,
    },
  ]

  return (
    <Dialog
      dialogKey={"pwdEdit"}
      visible={visible}
      title={t("dialogs.pwdEdit.title")}
      content={
        <Form
          initialValues={initialValues}
          fields={fields}
          loading={loading}
          submitText={t("dialogs.pwdEdit.submit")}
          onSubmit={submit}
        />
      }
      classCard={"card"}
      visibleBottom={false}
    />
  )
}

export default DialogPwdEdit
