import React from "react"

import classnames from "classnames"
import PropTypes from "prop-types"

import Icon from "@atoms/icon"

import { NavigationItemStyled } from "./navigation-item.styled"

const propTypes = {
  /** Proptypes go here */
  iconName: PropTypes.string,
  title: PropTypes.string,
  selected: PropTypes.bool,
}

const defaultProps = {
  /** Default Props go here */
}

const NavigationItem = ({
  iconName,
  title,
  selected,
  className,
  ...otherProps
}) => (
  <NavigationItemStyled
    className={classnames({ selected: selected }, className)}
    {...otherProps}
  >
    <Icon className={"icon"} name={iconName} />
    <div className={"title"}>{title}</div>
  </NavigationItemStyled>
)

NavigationItem.propTypes = propTypes
NavigationItem.defaultProps = defaultProps

export default NavigationItem
