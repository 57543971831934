import React from "react"
import { useSelector } from "react-redux"

import classnames from "classnames"
import { navigate } from "gatsby"

import { getTenant } from "@store/tenant/selectors"

import { LogoStyled, LogoWrapper } from "./logo.styled"

const isDev = process.env.NODE_ENV === "development"

const generic = require("@assets/images/generic.png")

const Logo = ({ className, full, navigateTo }) => {
  const tenant = useSelector(getTenant)
  const { logo } = tenant || {}
  const { publicUrl } = logo || {}

  const DynamicLogo = LogoStyled

  return (
    <LogoWrapper>
      <DynamicLogo
        className={classnames({ full: full }, className)}
        src={publicUrl || generic}
        alt="Hybreed Logo"
        onClick={() => isDev && navigate(navigateTo || "/")}
      />
    </LogoWrapper>
  )
}

export default Logo
