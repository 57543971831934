import styled from "styled-components"

const paddingH = "16px"

export const HeaderNavigationStyled = styled.div`
  display: flex;
  flex-direction: row;

  .headerNavigationItem {
    color: ${({ theme }) => theme.palette.common.grey};
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
    text-transform: uppercase;
    font-size: 14px;
    padding: 16px ${paddingH};
    text-decoration: none;
    position: relative;

    &.active {
      color: ${({ theme }) => theme.palette.common.black};

      &:after {
        content: "";
        display: block;
        width: calc(100% - ${paddingH} * 2);
        height: 3px;
        background-color: ${({ theme }) => theme.palette.accent.main};
        bottom: -1px;
        left: ${paddingH};
        position: absolute;
        border-radius: 100px;
      }
    }
  }
`
